@import 'src/styles/variables';

.courses {
  padding-bottom: 8px;

  &-title {
    margin-bottom: 1rem;
  }
  &-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1.25rem;
  }

  @media screen and (min-width: $screen-sm) {
    &-title {
      margin-bottom: 2.25rem;
    }
  }
  @media screen and (min-width: $screen-sm) and (max-width: $screen-lg) {
    &-list {
      justify-content: space-between;
    }
    .course-card {
      width: calc(50% - 1rem);
    }
  }
  @media screen and (min-width: $screen-lg) {
    &-list {
      column-gap: 1.25rem;
    }
  }
}
