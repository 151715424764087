@import 'variables';

.MuiButton {
  &-outlined {
    border: 1px solid $color-blue-2;
    color: $color-blue-2;
    text-transform: none;
  }
}

.MuiMenuItem-root {
  padding: 0;
}

.MuiPaper-root {
  box-shadow: none;
}
