@import 'src/styles/variables';

.admin {
  .title {
    color: $color-blue-2;
    margin: 0.5rem 0 1.5rem;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .text-empty {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .wrap-create {
    margin-bottom: 1rem;
    padding: 1rem 1rem;
    max-width: 16rem;
    background-color: white;
    border-radius: 1rem;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
    transition: background-color 0.3s ease;
    box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.1);

    &__btn {
      padding: 0.5rem;
      background-color: $color-blue-2;
      color: white;
      border-radius: 6px;
      border: 0;
      width: 100%;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
    }

    &__icon {
      margin-left: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  .loader {
    display: block;
    margin: 0 auto;
  }

  @media screen and (min-width: $screen-sm) {
    .title {
      font-size: 1.75rem;
    }
  }
  .course {
    margin: 1.25rem 0;
    padding: 1rem;
    height: 4rem;
    background-color: white;
    border-radius: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.1);

    &__title {
      margin-right: auto;
      text-decoration: underline;
      color: $color-blue-2;
      font-size: 1rem;
      &_gray {
        color: $color-grey-5 !important;
        pointer-events: none;
      }
    }

    &__date {
      margin-right: 4px;
      padding: 0.5rem;
      border: 1px solid $color-blue-2;
      border-radius: 1.5rem;
    }
    &__button {
      border: none;
      align-items: center;
      &:disabled {
        cursor: default;
      }
    }
    &__icon_gray path {
      stroke: $color-grey-5;
      stroke-width: 1.5;
    }
  }
}
