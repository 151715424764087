@import 'src/styles/variables';

.course {
  &-intro {
    padding-top: 2rem;

    &__title {
      margin-bottom: 1.5rem;
      font-size: 1.75rem;
      line-height: 2rem;
    }
  }
  &-intro-wrap__img-mobile {
    display: block;
    width: 100%;
    margin-top: -25%;
    margin-bottom: -55%;
  }
  &-intro-wrap__img {
    display: none;
  }
  &-summary {
    .wrap {
      display: flex;
      margin-bottom: 1.5rem;
    }
    &__title {
      margin-bottom: 0.75rem;
    }
  }
  &__start-btn {
    display: block;
    width: 100%;
    height: 2.25rem;
    padding: 0.5rem 1rem;
    margin-bottom: 1.5rem;
    border: none;
    border-radius: 4px;
    background-color: $color-blue-2;
    color: #fff;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
  }

  &-content {
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 1.5rem;

    &__title {
      margin-bottom: 1rem;
      font-size: 1.25rem;
      line-height: 1.5rem;
    }
    &-header {
      margin-bottom: 1.5rem;

      &__name {
        color: $color-blue-2;
        font-size: 1.125rem;
        line-height: 1.75rem;
        font-weight: 700;
      }
      &__progress {
        color: $color-grey-3;
        font-size: 0.75rem;
        line-height: 1rem;
      }
    }
  }

  .accordion {
    border: 1px solid $color-grey-4;
    border-radius: 8px;

    .MuiAccordionSummary-content,
    .MuiAccordionDetails-root,
    .MuiAccordion-root.Mui-expanded {
      margin: 0;
      padding: 0;
    }
    &-module {
      &__name {
        min-height: 0;
        padding: 10px;
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 500;
        background-color: rgba(70, 181, 234, 0.18);
      }
    }
    &-lesson-separator {
      height: 1px;
      background-color: $color-grey-4;
    }
  }

  @media screen and (min-width: $screen-sm) {
    &__start-btn {
      max-width: 13rem;
      height: 2.75rem;
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
    &-intro-wrap {
      position: relative;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;

      &__img {
        display: block;
        position: absolute;
        top: -50%;
        right: 0;
        z-index: -1;
      }
      &__img-mobile {
        display: none;
      }
    }
    &-intro {
      max-width: 66.6%;

      &__title {
        line-height: 2.5rem;
      }
    }

    &-summary {
      .wrap {
        justify-content: space-between;
        column-gap: 0.75rem;
      }
      &__item {
        padding: 1.25rem 2rem;
        border-radius: 1.5rem;
      }
    }

    &-content {
      max-width: 38.75rem;
      padding: 2rem;
    }
  }

  @media screen and (min-width: $screen-md) {
    &-intro-wrap {
      margin-bottom: 3rem;
    }
    &-summary {
      .wrap {
        margin-bottom: 1.75rem;
      }
      &__title {
        margin-bottom: 1rem;
      }
    }
    &__start-btn {
      margin-bottom: 1.75rem;
    }
    &-content__title {
      font-size: 1.5rem;
      line-height: 1.75rem;
    }
  }

  @media screen and (max-width: $screen-md) {
    &__start-btn {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
