@import 'src/styles/variables';

.custom-popover {
  display: flex;
  align-items: center;
  &__icon {
    height: 1rem;
    width: 1rem;
    cursor: pointer;
  }
  &__text {
    padding: 0.5rem;
    color: $color-blue-2;
    font-weight: 500;
  }
}
