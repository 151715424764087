@import 'src/styles/variables';

.tabs {
  display: inline-flex;
  margin: 0 0 1.5rem;
  box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.1);

  &__item {
    margin-right: 0.5rem;
    background-color: white;
    border-radius: 4px;
    color: black;
    text-transform: none;
    font-size: 1.125rem;
    box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.1);
    &:last-child {
      margin-right: 0;
    }
    &_selected {
      color: $color-blue-2 !important;
    }
  }
}
