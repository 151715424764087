@import 'src/styles/variables';

.header {
  padding: 1rem 0;

  .container,
  .nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .container {
    justify-content: space-between;
  }

  .logo {
    width: 15rem;
  }

  .nav {
    flex-direction: column;
    color: $color-blue-2;
    font-size: 1.125rem;
    line-height: 1.875rem;
    font-weight: 600;
    order: 1;
    align-items: start;
  }

  .menu-btn {
    display: flex;
    column-gap: 8px;
    height: 2rem;
    padding: 8px 4px;
    border-radius: 8px;
    background-color: #fff;
  }

  .account-menu {
    .MuiList-root {
      padding: 1rem;
    }

    &__name {
      color: $color-blue-2;
      font-weight: 600;
    }
    &__email {
      font-size: 0.75rem;
    }
    &__link {
      font-size: 0.875rem;
      font-weight: 400;
      color: $color-custom-black;
      padding: 0;
      &_quit {
        color: #e64c4c;
        font-weight: 600;
      }
    }
  }
}

@media screen and (min-width: $screen-md) {
  .header {
    .container {
      justify-content: space-between;
    }

    .nav {
      column-gap: 3rem;
      flex-direction: row;
      order: 0;
    }
  }
}
