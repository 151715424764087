// Colors
$color-blue-1: #00add8;
$color-blue-2: #0d56bd;
$color-blue-3: #137ccf;
$color-blue-4: #46b5ea;
$color-grey-1: #d1d5db;
$color-grey-2: #f4f4f4;
$color-grey-3: #717271;
$color-grey-4: #e6e6e6;
$color-grey-5: #667085;
$color-custom-black: #1b1d1a;
$color-orange-1: #ba4615;
$color-orange-2: #fa9833;
$color-green-1: #2ecc71;
$link-visited: #6b4ba1;

// Breakpoints
$screen-sm: 576px;
$screen-md: 992px;
$screen-lg: 1280px;
$screen-xl: 1440px;
