@import 'src/styles/variables';

.role-page {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 1rem;
    color: $color-blue-2;
  }
  &__email {
    margin-bottom: 1rem;
    &-text {
      margin-bottom: 0.5rem;
    }
    &-input {
      margin-bottom: 0.5rem;
      min-width: 13rem;
      height: 1.5rem;
      margin-right: 1rem;
      padding-left: 0.5rem;
    }
    &-button {
      width: 13.875rem;
      margin-bottom: 0.5rem;
      background-color: $color-blue-2;
      color: #fff;
      padding: 0.5rem 1.25rem;
      border-radius: 6px;
      border: 0;
      font-size: 16px;
    }
  }
  &-result {
    &__label {
      font-size: 16px;
      font-weight: bold;
    }
  }
}

.checkbox {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 0;

  &-container {
    &__text {
      margin-top: 1rem;
      font-size: 16px;
      font-weight: 600;
    }
    &__item {
      display: flex;
      align-items: center;
      &:first-child {
        margin-top: 8px;
      }
      &:last-child {
        margin-bottom: 8px;
      }
    }
    &__role {
      font-size: 16px;
      font-weight: 600;
      margin-right: 0.5rem;
    }
    &__button {
      width: 13.875rem;
      background-color: $color-blue-2;
      color: #fff;
      padding: 0.5rem 1.25rem;
      border-radius: 6px;
      border: 0;
      font-size: 16px;
    }
  }
}
