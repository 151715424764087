@use 'sass:map';

$rem: 16;
$space-values: ();
$font-sizes: ();

.d-flex {
  display: flex;
}

@for $i from 8 through 100 {
  @if $i % 2 == 0 {
    $space-values: map.set($space-values, $i, calc($i / $rem * 1rem));
  }
}
@for $i from 8 through 40 {
  $font-sizes: map.set($font-sizes, $i, calc($i / $rem * 1rem));
}

$space-utilities: (
  'margin': (
    property: margin,
    class: m,
    values:
      map.merge(
        $space-values,
        (
          auto: auto,
        )
      ),
  ),
  'margin-x': (
    property: margin-right margin-left,
    class: mx,
    values:
      map.merge(
        $space-values,
        (
          auto: auto,
        )
      ),
  ),
  'margin-y': (
    property: margin-top margin-bottom,
    class: my,
    values:
      map.merge(
        $space-values,
        (
          auto: auto,
        )
      ),
  ),
  'margin-top': (
    property: margin-top,
    class: mt,
    values:
      map.merge(
        $space-values,
        (
          auto: auto,
        )
      ),
  ),
  'margin-right': (
    property: margin-right,
    class: mr,
    values:
      map.merge(
        $space-values,
        (
          auto: auto,
        )
      ),
  ),
  'margin-bottom': (
    property: margin-bottom,
    class: mb,
    values:
      map.merge(
        $space-values,
        (
          auto: auto,
        )
      ),
  ),
  'margin-left': (
    property: margin-left,
    class: ml,
    values:
      map.merge(
        $space-values,
        (
          auto: auto,
        )
      ),
  ),
  'padding': (
    property: padding,
    class: p,
    values: $space-values,
  ),
  'padding-x': (
    property: padding-right padding-left,
    class: px,
    values: $space-values,
  ),
  'padding-y': (
    property: padding-top padding-bottom,
    class: py,
    values: $space-values,
  ),
  'padding-top': (
    property: padding-top,
    class: pt,
    values: $space-values,
  ),
  'padding-right': (
    property: padding-right,
    class: pr,
    values: $space-values,
  ),
  'padding-bottom': (
    property: padding-bottom,
    class: pb,
    values: $space-values,
  ),
  'padding-left': (
    property: padding-left,
    class: pl,
    values: $space-values,
  ),
  'column-gap': (
    property: column-gap,
    class: col-gap,
    values: $space-values,
  ),
  'row-gap': (
    property: row-gap,
    class: row-gap,
    values: $space-values,
  ),
);

//Font
$font-utilities: (
  'font-size': (
    property: font-size,
    class: fs,
    values: $font-sizes,
  ),
  'font-weight': (
    property: font-weight,
    class: fw,
    values: (
      300: 300,
      400: 400,
      500: 500,
      600: 600,
      700: 700,
      800: 800,
    ),
  ),
);

@mixin generate-utility($utility, $infix: '') {
  $values: map.get($utility, values);

  @each $key, $value in $values {
    $properties: map.get($utility, property);

    @if type-of($properties) == 'string' {
      $properties: append((), $properties);
    }

    $property-class: if(map-has-key($utility, class), map-get($utility, class), nth($properties, 1));
    $property-class: if($property-class == null, '', $property-class);
    $property-class-modifier: if($key, if($property-class == '' and $infix == '', '', '-') + $key, '');

    .#{$property-class + $infix + $property-class-modifier} {
      @each $property in $properties {
        #{$property}: $value;
      }
    }
  }
}

@each $key, $utility in $space-utilities {
  @if type-of($utility) == 'map' {
    @include generate-utility($utility);
  }
}

@each $key, $utility in $font-utilities {
  @if type-of($utility) == 'map' {
    @include generate-utility($utility);
  }
}
