@import 'src/styles/variables';

$corner-img-max-width: 21.625rem;

.auth {
  display: flex;
  align-items: center;
  height: 100vh;
  background: linear-gradient(90deg, #a1c4fd, #c2e9fb, #e0eafc);
  overflow: hidden;

  &-container {
    position: relative;
    margin: 0 auto;
    padding: 0 1.5rem;

    @media screen and (min-width: $screen-sm) {
      width: 25.75rem;
    }
  }

  &-welcome,
  &-rules {
    background-color: #fff;
    border-radius: 1rem;
  }

  &-welcome {
    margin-bottom: 1rem;
    padding: 2rem;
  }

  &-logo {
    display: block;
    margin-bottom: 4rem;
  }

  &-corner-img {
    position: absolute;
    top: -5.75rem;
    left: 12rem;

    @media screen and (max-width: $screen-sm) {
      top: -6.8rem;
      left: 9.6rem;
      max-width: calc($corner-img-max-width * 0.76);
    }
  }

  &-title {
    margin-bottom: 1rem;
    color: $color-custom-black;
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  &-info {
    margin-bottom: 1rem;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  &-btn {
    border: 1px solid $color-blue-2;
    border-radius: 4px;
    overflow: hidden;
  }

  &-rules {
    padding: 1rem;
    border-radius: 1rem;
    font-size: 0.75rem;
    line-height: 0.875rem;

    a {
      text-decoration: underline;
    }
  }
  &-back-btn {
    width: 100%;
    padding: 0.625rem 1rem;
    line-height: 1.25rem;
    font-weight: 600;

    img {
      margin-right: 0.5rem;
    }
  }
}
