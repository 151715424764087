@import 'src/styles/variables';

.course-card {
  position: relative;
  padding: 1rem;
  border-radius: 1rem;
  background-color: #fff;

  &__btn {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
    padding: 4px;
    background-color: $color-grey-2;
    border-radius: 50%;
    border: none;
    &:hover {
      background-color: rgba(244, 244, 244, 0.5);
      path {
        stroke: $color-blue-2;
      }
    }
    &_saved path {
      stroke: $color-blue-2;
      fill: $color-blue-2;
      fill-opacity: 0.8;
    }
  }
  &__img {
    width: 100%;
    margin-bottom: 8px;
  }
  &__title {
    display: block;
    padding: 1rem 0 0.5rem;
    color: $color-blue-2;
    text-decoration: underline;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  @media screen and (min-width: $screen-lg) {
    max-width: 17.5rem;
  }
}
