@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap');
@import './styles/variables';
@import './styles/mixins';
@import './styles/utilities';
@import './styles/override';

div,
p,
form,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
table {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
}

a {
  color: $color-blue-2;
  text-decoration: none;
  &:hover {
    color: $color-blue-4;
  }
  &:visited {
    color: $link-visited;
  }
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $color-custom-black;
  background-color: $color-grey-2;
}

h1,
h2,
h3,
h4 {
  color: $color-blue-2;
  font-weight: 500;
}

h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

h2 {
  font-size: 1.875rem;
  line-height: 2.375rem;
}

h3 {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

h4 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

button {
  background-color: white;
  cursor: pointer;
}

label {
  font-size: 0.75rem;
  line-height: 1rem;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1.5rem;

  @media screen and (max-width: $screen-sm) {
    padding: 0 1rem;
  }
}
